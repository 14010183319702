import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {flushSync} from 'react-dom';
import toast from 'react-hot-toast';

import {useAssetsReducer} from './assets-reducer';
import {initSocket, parseQueryString} from './util';
import {setUrlParam} from '../../utils/common/funcs';
import {
  addEvent,
  addPack,
  renamePack as apiRenamePack,
  deleteAsset,
  delEvent,
  delPack,
  findEvents,
  findPacks,
  getPack,
  favoriteAsset as apiFavoriteAsset,
  unfavoriteAsset as apiUnfavoriteAsset,
  moveAssets as apiMoveAssets,
  renameAsset as apiRenameAsset,
  renameEvent as apiRenameEvent,
} from '../../utils/common/packs';
import {useOperator} from '../OperatorContext';

const AssetsContext = React.createContext();

function AssetsProvider({children}) {
  const {op: isLoggedIn} = useOperator();
  const [state, dispatch] = useAssetsReducer();
  const stateRef = useRef(state);
  stateRef.current = state;

  const [, setForceRender] = useState({});

  if (
    (state?.currentPack?.name && window.location.path === '/asset-manager') ||
    window.location.path === '/event-manager'
  ) {
    setUrlParam('pack', state.currentPack.name);
    setUrlParam('event', state.currentEvent.name);
  }

  //-------------------------Events-----------------------------

  /**
   * Create a new event for the current operator
   * @param {string} name - Event name
   * @param {string} location - Location name
   * @returns Promise
   */
  async function createEvent(name, location) {
    try {
      const newEvent = await addEvent(name, location);
      dispatch({type: 'event.create', value: {event: newEvent}});
    } catch (err) {
      toast.error(`Unable to add event: ${name}`);
      console.error(err);
    }
  }

  /**
   * Select an event, load its packs
   * @param {string} name - event to select
   * @returns Promise
   */
  async function selectEvent(name) {
    if (!state.events) {
      console.warn('Tried to load events when none were loaded');
      return;
    }

    dispatch({type: 'event.select', value: {event: name}});

    try {
      const apiPacks = await findPacks(name);
      flushSync(() => {
        dispatch({type: 'packs.load', value: {packs: apiPacks}});
      });

      if (stateRef.current.currentPack) {
        const apiPack = await getPack(name, stateRef.current.currentPack?.name);
        dispatch({type: 'pack.load', value: {pack: apiPack}});
      }
    } catch (err) {
      console.error('Failed to find packs', err);
    }
  }

  /**
   * Rename an event
   * @param {string} oldName - old event name
   * @param {string} newName - new event name
   * @returns Promise
   */
  async function renameEvent(oldName, newName) {
    try {
      const {event, errors} = await apiRenameEvent(oldName, newName);
      if (errors.errors?.length) {
        toast.error(`Renaming partially failed:\n${errors.join('\n')}`);
      }
      dispatch({type: 'event.rename', value: {event, oldName}});
      return event;
    } catch (err) {
      toast.error(`Unable to rename event: ${oldName}`);
      console.error(err);
    }
  }

  /**
   * Delete an event from the current pack
   * @param {string} name - event to delete
   */
  async function deleteEvent(name) {
    try {
      const targetEvent = stateRef.current.events.find((e) => e.name === name);
      if (targetEvent) {
        await delEvent(targetEvent.name);
        dispatch({type: 'event.delete', value: {event: targetEvent}});
      } else {
        toast.error(`Tried to delete nonexistent event: ${name}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  //-------------------------Packs-----------------------------

  /**
   * Create a pack for the currently selected event, refresh packs
   * @param {string} name - pack to create
   */
  async function createPack(name) {
    try {
      const result = await addPack(stateRef.current.currentEvent.name, name);
      dispatch({type: 'pack.create', value: {pack: result}});
      return result;
    } catch (err) {
      console.error('Unable to add pack', err);
    }
  }

  /**
   * Select a pack, load its assets
   * @param {string} name - pack to select
   */
  async function selectPack(name) {
    if (!stateRef.current.packs) {
      console.error('Tried to select pack when none were loaded');
      return;
    }

    // Pack already selected
    if (stateRef.current.currentPack?.name === name) {
      return;
    }

    if (stateRef.current.packs.find((p) => p.name === name)) {
      try {
        const apiPack = await getPack(
          stateRef.current.currentEvent?.name,
          name,
        );
        dispatch({type: 'pack.load', value: {pack: apiPack}});
      } catch (err) {
        console.error('Failed to find assets', err);
      }
    } else {
      const errMsg = `Selected pack "${name}" could not be found`;
      toast.error(errMsg);
      console.error(errMsg);
      throw errMsg;
    }
  }

  /**
   * Rename a pack
   * @param {string} packId - pack to rename
   * @param {string} newName - new pack name
   * @returns Promise
   */
  async function renamePack(packId, newName) {
    try {
      const {pack, errors} = await apiRenamePack(packId, newName);
      if (errors.errors?.length) {
        toast.error(`Renaming partially failed:\n${errors.join('\n')}`);
      }

      dispatch([{type: 'pack.rename', value: {pack}}]);
    } catch (err) {
      toast.error(`Unable to rename pack: ${packId}`);
      console.error(err);
    }
  }

  /**
   * Delete current pack, refresh events and packs
   */
  async function deletePack(eventName, packName) {
    try {
      const result = await delPack(eventName, packName);
      flushSync(() => {
        dispatch({type: 'pack.delete', value: {packName}});
      });
      if (stateRef.current.currentPack) {
        const packResponse = await getPack(
          stateRef.current.currentEvent.name,
          stateRef.current.currentPack.name,
        );
        dispatch({type: 'pack.load', value: {pack: packResponse}});
      }

      return result;
    } catch (err) {
      console.error(err);
      toast.error('Unable to delete pack');
    }
  }

  //-------------------------Assets-----------------------------

  /**
   * Rename an asset, refresh assets
   * @param {string} oldName - asset to rename
   * @param {string} newName - new name for asset
   */
  async function renameAsset(oldName, newName) {
    try {
      const {asset: renamed, errors} = await apiRenameAsset(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
        oldName,
        newName,
      );
      if (errors?.length) {
        toast.error(`Renaming partially failed:\n${errors.join('\n')}`);
      }

      dispatch({type: 'asset.rename', value: {asset: renamed, oldName}});
      return renamed;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Delete an asset or array of assets from the current pack, by name. Refresh assets.
   * @param {[String] | String} names - name or names of assets to delete
   */
  async function deleteAssets(names) {
    const namesDelimited = Array.isArray(names) ? names.join(',') : names;

    try {
      const result = await deleteAsset(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
        namesDelimited,
      );
      const packResponse = await getPack(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
      );
      flushSync(() => {
        dispatch({type: 'pack.load', value: {pack: packResponse}});
      });
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Favorite an asset from the current pack, by name. Refresh assets.
   * @param {[String] | String} name - name or names of assets to delete
   */
  async function favoriteAsset(name) {
    try {
      const result = await apiFavoriteAsset(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
        name,
      );
      const packResponse = await getPack(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
      );
      flushSync(() => {
        dispatch({type: 'pack.load', value: {pack: packResponse}});
      });
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Unfavorite an asset from the current pack, by name. Refresh assets.
   * @param {[String] | String} name - name or names of assets to delete
   */
  async function unfavoriteAsset(name) {
    try {
      const result = await apiUnfavoriteAsset(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
        name,
      );
      const packResponse = await getPack(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
      );
      flushSync(() => {
        dispatch({type: 'pack.load', value: {pack: packResponse}});
      });
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Move an asset or array of assets from the current pack, by name. Refresh assets.
   * @param {Object} targetPack - name of pack to move assets to
   * @param {Object[]} itemsToMove - array of assets to move
   */
  async function moveAssets(targetPack, itemsToMove) {
    const itemIds = itemsToMove.map((item) => item._id);

    try {
      const res = await apiMoveAssets(targetPack._id, itemIds);
      if (res.errors.length === itemIds.length) {
        toast.error(`Move failed:\n${res.errors.join('\n')}`);
      } else if (res.errors.length) {
        toast.error(`Move partially failed:\n${res.errors.join('\n')}`);
      } else {
        toast.success('Item(s) moved successfully');
      }
    } catch (err) {
      toast.error('Error moving item(s)');
      throw err;
    }

    try {
      const apiPack = await getPack(
        stateRef.current.currentEvent?.name,
        targetPack.name,
      );
      dispatch({type: 'pack.load', value: {pack: apiPack}});
    } catch (err) {
      console.error('Failed to find assets', err);
    }
  }

  //---------------------------Uploads-----------------------------

  /*
   * Upload files to a pack and keep track of their progress
   * @param {Array<File>} files - files to upload
   * @param {string} event - event directory name
   * @param {string} pack - name of pack
   * @param {Object} uploadInfo - progress of uploads
   */
  function uploadFiles(files, event, pack, uploadInfo) {
    const worker = new Worker('/upload-worker.js');
    worker.postMessage({
      type: 'setToken',
      token: localStorage.getItem('token'),
    });

    return files.map((file) => {
      return new Promise((resolve, reject) => {
        const uploadId = file.path + file.dropTime;

        if (!uploadInfo.current[uploadId]) {
          uploadInfo.current[uploadId] = {
            name: file.name,
            nameNoExt: file.name.replace(/\.[^/.]+$/, ''),
            packName: pack,
            percentage: 0,
            path: file.path.includes('/') ? file.path : `${pack}/${file.name}`,
            status: 'pending',
            dropTime: file.dropTime,
            mime: file.type,
          };
        }

        worker.onmessage = function (e) {
          const {type, uploadId: upId, percentage, error, asset} = e.data;

          switch (type) {
            case 'progress':
              uploadInfo.current[upId].percentage = percentage;
              break;
            case 'complete':
              uploadInfo.current[upId].status = 'fulfilled';
              uploadInfo.current[upId].percentage = 100;
              resolve(asset);
              break;
            case 'error':
              uploadInfo.current[upId].status = 'error';
              uploadInfo.current[upId].error = error;
              toast.error(error);
              break;
          }

          setForceRender(Object.entries(uploadInfo.current));
        };

        // Start the upload in the worker
        worker.postMessage({file, event, pack, uploadId});
      });
    });
  }

  //-------------------------Init-----------------------------

  // Establish websocket connection
  const trySocketConnection = useCallback(
    (userId = null) => {
      function onMessage(wsType, payload) {
        dispatch({type: 'socket.message', value: {wsType, payload}});
      }
      const socket = initSocket(onMessage, userId);
      if (socket) {
        dispatch({type: 'socket.connect', value: {socket}});
      } else {
        console.error('Failed to establish websocket connection');
      }
    },
    [dispatch],
  );
  useEffect(() => {
    if (isLoggedIn) {
      trySocketConnection();
    }
  }, [isLoggedIn, trySocketConnection]);

  // Init store
  useEffect(() => {
    async function init() {
      const {event: initialEvent, pack: initialPack} = parseQueryString(
        window.location.search,
      );

      const apiEvents = await findEvents();
      flushSync(() => {
        dispatch({
          type: 'events.load',
          value: {events: apiEvents, initialEvent},
        });
      });

      if (!stateRef.current.currentEvent) return;
      const apiPacks = await findPacks(stateRef.current.currentEvent.name);
      flushSync(() => {
        dispatch({type: 'packs.load', value: {packs: apiPacks, initialPack}});
      });

      if (!stateRef.current.currentPack) return;
      const packResponse = await getPack(
        stateRef.current.currentEvent.name,
        stateRef.current.currentPack.name,
      );
      flushSync(() => {
        dispatch({type: 'pack.load', value: {pack: packResponse}});
      });
    }

    if (isLoggedIn) {
      init().catch((err) => {
        console.error('Asset context init error: ', err);
      });
    }
  }, [dispatch, isLoggedIn]);

  //-------------------------END Init-----------------------------

  return (
    <AssetsContext.Provider
      value={{
        // Events
        currentEvent: state?.currentEvent,
        events: state?.events,
        createEvent,
        selectEvent,
        deleteEvent,
        renameEvent,
        // Packs
        currentPack: state?.currentPack,
        packs: state?.packs,
        createPack,
        selectPack,
        renamePack,
        deletePack,
        // Assets
        currentAsset: state?.currentAsset,
        assets: state?.assets,
        favoriteAsset,
        unfavoriteAsset,
        renameAsset,
        deleteAssets,
        moveAssets,
        uploadFiles,
        // Utility
        trySocketConnection,
        packPrice: state?.packPrice,
        dispatch,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
}

function useAssets() {
  const context = useContext(AssetsContext);
  if (!context) {
    throw new Error('useAssets must be used within an AssetsProvider');
  }

  return context;
}

export {useAssets, AssetsProvider};
export default AssetsContext;
