import React from 'react';
import {View, Text} from 'react-native';

import {useTheme} from '../context/ThemeContext';

const GB = 1_000_000_000;
const TB = 1_000_000_000_000;

const warningPercentage = 85;
const criticalPercentage = 95;
const fullPercentage = 100;
const warningMessage =
  'Warning: You are running out of storage. Please delete some files to avoid reaching your storage limit.';
const fullMessage = 'Storage limit reached. Please delete some files.';

export default function StorageBar({bytesUsed = 0, bytesLimit = 0}) {
  const {style, values} = useTheme(getThemedStyles);
  if (typeof bytesUsed !== 'number' || typeof bytesLimit !== 'number') {
    bytesUsed = 0;
    bytesLimit = 0;
  }
  const percentageUsed = (bytesUsed / bytesLimit) * 100 || 0;

  let status = 'normal';
  if (percentageUsed >= warningPercentage) status = 'warning';
  if (percentageUsed >= criticalPercentage) status = 'critical';
  if (percentageUsed >= fullPercentage) status = 'full';

  let barColor = values.GREEN;
  if (status === 'warning') barColor = values.YELLOW;
  if (status === 'critical') barColor = values.RED;
  if (status === 'full') barColor = values.RED;

  let message = '';
  if (status === 'warning' || status === 'critical') message = warningMessage;
  if (status === 'full') message = fullMessage;

  const bytesUsedFormatted =
    bytesUsed >= TB
      ? `${Number((bytesUsed / TB).toFixed(2)).toString()} TB`
      : `${Number((bytesUsed / GB).toFixed(2)).toString()} GB`;
  const bytesLimitFormatted =
    bytesLimit >= TB
      ? `${Number((bytesLimit / TB).toFixed(2)).toString()} TB`
      : `${Number((bytesLimit / GB).toFixed(2)).toString()} GB`;

  return (
    <View style={style.container}>
      <View style={style.storageBarTextContainer}>
        <Text style={style.storageBarText}>
          Storage Used: {Number(percentageUsed.toFixed(2)).toString()}%
        </Text>
        <Text style={style.storageBarText}>
          {bytesUsedFormatted} / {bytesLimitFormatted}
        </Text>
      </View>
      <View style={style.storageBar}>
        <View
          style={[
            style.storageBarFill,
            {backgroundColor: barColor},
            {width: `${percentageUsed}%`},
          ]}
        />
      </View>
      {message ? <Text style={style.warningText}>{message}</Text> : null}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  storageBar: {
    width: '100%',
    height: 14,
    backgroundColor: theme.BGSECOND,
    borderRadius: 10,
    borderColor: theme.FIRST,
    borderWidth: 2,
    justifyContent: 'center',
  },
  storageBarFill: {
    height: '100%',
    maxWidth: '100%',
    borderRadius: 6,
  },
  storageBarText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
  },
  storageBarTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  warningText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginTop: 10,
  },
});
