import etherFetch from '../ether-fetch/etherFetch';

/**
 * Get all promos for the logged-in user
 * @returns {Promise<Promo[]>}
 */
export function fetchPromos() {
  return etherFetch('/promos', {method: 'GET'}).then((res) => res.json());
}

/**
 * Create new Promo for the logged-in user
 * @param {Number} qty
 * @param {Number} amount
 * @param {'percentage'|'fixed'} type
 * @param {'order'|'items'} target
 * @param {boolean} enabled - Whether the promotion is active
 * @returns {Promise<Promo[]>}
 */
export function createPromo({qty, amount, type, target, active}) {
  return etherFetch('/promos', {
    method: 'POST',
    body: {qty, amount, type, target, active},
  }).then((res) => res.json());
}

/**
 * Update existing promo
 * @param {string} promoId
 * @param {Object} updates
 * @returns {Promise<Promo>}
 */
export function updatePromo(promoId, updates) {
  return etherFetch(`/promos/${promoId}`, {
    method: 'PUT',
    body: updates,
  }).then((res) => res.json());
}

/**
 * Delete promo
 * @param {string} promoId
 * @returns {Promise}
 */
export function deletePromo(promoId) {
  return etherFetch(`/promos/${promoId}`, {
    method: 'DELETE',
  }).then((res) => res.json());
}
