import {faVideoCamera} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {View, Text, Image, Modal} from 'react-native';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import LoadingStore from './LoadingStore';
import EMLogoFallbackPNG from '../../assets/EtherMediaIcon.png';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import ImageViewerComponent from '../../components/ImageViewer';
import PoweredBy from '../../components/PoweredBy';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';
import {formatTimestamp} from '../../utils/common/funcs';
import {getSafeAssets, getSafeEventWithPacks} from '../../utils/common/stores';

export default function StoreAssetView({route}) {
  const {style} = useTheme(getThemedStyles);
  const [assets, setAssets] = useState([]);
  const [currentPack, setCurrentPack] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [storeInfo, setStoreInfo] = useState(false);
  const [eventInfo, setEventInfo] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const {storeSlug, packName, eventId} = route.params;
  const {addItem} = useCart();

  useEffect(() => {
    async function loadStore() {
      setLoaded(false);

      let redirectToStore = false;

      try {
        const infoPms = getSafeEventWithPacks(storeSlug, eventId);

        const {event, store, hasAccess} = await infoPms;

        if (!hasAccess) {
          window.location.replace(
            `/store/${storeSlug}/pack?eventId=${eventId}`,
          );
          return;
        }

        const assetPms = getSafeAssets(packName, eventId);

        const {assets: fetchedAssets, pack} = await assetPms;
        const sortedAssets = [...fetchedAssets].sort((a, b) => {
          if (a.favorite && !b.favorite) return -1;
          if (!a.favorite && b.favorite) return 1;
          return 0;
        });

        setCurrentPack(pack);
        setAssets(sortedAssets);
        setStoreInfo(store);
        setEventInfo(event);
        setLoaded(true);
      } catch (err) {
        console.error(err);
        const {msg} = await err.json();
        redirectToStore = msg === 'Event not found';
      } finally {
        if (redirectToStore) {
          window.location.replace(`/store/${storeSlug}`);
        } else {
          setLoaded(true);
        }
      }
    }
    if (storeSlug && packName) {
      loadStore();
    }
  }, [storeSlug, packName, eventId]);

  function closeImageViewer() {
    setSelectedIndex(null);
  }

  function addPack() {
    addItem({
      name: currentPack.name,
      packId: currentPack.id,
      event: eventInfo.name,
      assets,
    });
  }

  const videoWatermarkSrc = storeInfo?.watermark || EMLogoFallbackPNG;

  if (!loaded) {
    return <LoadingStore />;
  }

  return (
    <View style={style.container}>
      <Modal visible={!!assets[selectedIndex]} transparent ariaHideApp={false}>
        <ImageViewerComponent
          onClose={closeImageViewer}
          assets={assets}
          videoWatermarkSrc={videoWatermarkSrc}
          currentIndex={selectedIndex}
          setIndex={setSelectedIndex}
        />
      </Modal>
      {storeInfo.logo ? (
        <Image
          style={style.userLogo}
          resizeMode="contain"
          source={storeInfo.logo}
        />
      ) : null}
      <Text style={style.subheader}>
        {eventInfo.location ? `${eventInfo.location} -` : ''}{' '}
        {formatTimestamp(eventInfo.createdAt)}
      </Text>
      <Text style={style.subheader}>{eventInfo.name}</Text>
      <Text style={style.header}>{packName}</Text>
      <EtherButton
        onPress={addPack}
        style={style.buyButton}
        text="Add to Cart"
      />

      <View style={style.imageContainer}>
        <ResponsiveMasonry columnsCountBreakPoints={{500: 1, 780: 2, 1100: 3}}>
          <Masonry gutter="12px">
            {assets.map((asset, index) => (
              <div style={{position: 'relative'}} key={asset._id}>
                <img
                  src={
                    storeInfo.showThumbWM
                      ? asset.thumbnailWatermark.url
                      : asset.thumb.url
                  }
                  style={style.asset}
                  onClick={() => setSelectedIndex(index)}
                  alt=""
                />
                {asset.mime.includes('video') ? (
                  <FaIcon
                    size={100}
                    icon={faVideoCamera}
                    style={style.videoIcon}
                  />
                ) : null}
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </View>
      <PoweredBy />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  asset: {
    width: '100%',
    display: 'block',
    cursor: 'pointer',
  },
  banner: {
    margin: 20,
    minWidth: 400,
    width: '100%',
    height: 320,
    borderRadius: 10,
    marginBottom: 40,
    mobile: {
      height: 240,
    },
  },
  buyButton: {
    marginTop: 40,
    marginBottom: 50,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.planetHeader,
    mobile: {
      fontSize: fontSize.header,
    },
  },
  image: {
    flex: 1,
    aspectRatio: 1,
    margin: 2,
  },
  imageContainer: {
    width: '100%',
    minWidth: 380,
    paddingHorizontal: '15%',
    marginBottom: 40,
    mobile: {
      paddingHorizontal: 50,
    },
  },
  subheader: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.header,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.bigBody,
    },
  },
  userLogo: {
    margin: 20,
    minWidth: 300,
    maxWidth: 600,
    height: 140,
  },
  videoIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'rgba(255, 255, 255, 0.8)',
    pointerEvents: 'none',
  },
});
