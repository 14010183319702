import {func, string} from 'prop-types';
import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import {ActivityIndicator, Pressable, View, Text, Modal} from 'react-native';

import EtherButton from './EtherButton';
import EtherDropdown from './EtherDropdown';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import {refundOrder, getRefundStatus} from '../utils/common/orders';

RefundModal.propTypes = {
  onPressClose: func.isRequired,
  orderId: string.isRequired,
  onRefundComplete: func,
};

const REFUND_STATUS = {
  not_refunded: {
    text: 'Not refunded',
    color: 'green',
  },
  succeeded: {
    text: 'Refunded',
    color: 'red',
  },
  canceled: {
    text: 'Not refunded\n(canceled refund)',
    color: 'green',
  },
  error: {
    text: 'Error loading status',
    color: 'red',
  },
};

export default function RefundModal({onPressClose, orderId, onRefundComplete}) {
  const {style, values} = useTheme(getThemedStyles);
  const [refundState, setRefundState] = useState('loading');
  const [selectedReason, setSelectedReason] = useState(null);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [refundReason, setRefundReason] = useState(null);

  useEffect(() => {
    async function checkRefundStatus() {
      try {
        const data = await getRefundStatus(orderId);
        setRefundState(data.refunded ? data.refundStatus : 'not_refunded');
        if (data.refundReason) {
          setRefundReason(data.refundReason);
        }
      } catch (err) {
        console.error('Error checking refund status:', err);
        setRefundState('error');
      }
    }
    checkRefundStatus();
  }, [orderId, refundState]);

  const refundOptions = [
    {value: 'duplicate', label: 'Duplicate purchase'},
    {value: 'fraudulent', label: 'Fraudulent'},
    {value: 'requested_by_customer', label: 'Requested by customer'},
  ];

  const statusDisplay = REFUND_STATUS[refundState] || {
    text: 'Unknown status',
    color: 'red',
  };

  async function handleRefund() {
    if (!selectedReason) {
      toast.error('Please select a reason for the refund');
      return;
    }

    setStatus(STATUS.BUSY);
    try {
      const json = await refundOrder(orderId, {
        reason: selectedReason.value,
      });
      if (!json.success || !json.refund) {
        throw new Error('Refund response missing data');
      }

      toast.success('Order refunded successfully');
      onRefundComplete();
      onPressClose();
    } catch (err) {
      console.error('Refund error:', err);
      toast.error(`Error refunding order: ${err.message}`);
    }
  }

  return (
    <Modal
      style={style.modal}
      animationType="fade"
      transparent
      ariaHideApp={false}
    >
      <Pressable style={style.outerArea} onPress={onPressClose}>
        <View
          style={style.container}
          onTouchEnd={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          {refundState === 'loading' ? (
            <ActivityIndicator size={40} color={values.FIRST} />
          ) : (
            <>
              <Text style={style.title}>Refund Menu</Text>
              <Text style={style.statusText}>
                Refund Status:{' '}
                <Text style={{color: statusDisplay.color}}>
                  {statusDisplay.text}
                </Text>
              </Text>
              {refundReason && refundState !== 'canceled' ? (
                <View style={style.refundReason}>
                  <Text style={style.refundText}>
                    Reason:{' '}
                    {refundOptions.find(
                      (option) => option.value === refundReason,
                    )?.label || refundReason}
                  </Text>
                  <Text style={style.disclaimerText}>
                    Refunds may take 5-10 business days to process.{'\n'}Thank
                    you for your patience.
                  </Text>
                </View>
              ) : (
                <View style={style.dropdown}>
                  <Text style={style.dropdownText}>
                    Provide a reason for refund
                  </Text>
                  {selectedReason?.value === 'fraudulent' ? (
                    <Text style={style.fraudText}>
                      This option will block this payment method permanently
                    </Text>
                  ) : null}
                  <EtherDropdown
                    options={refundOptions}
                    selected={selectedReason}
                    setSelected={setSelectedReason}
                    placeholder="Select a reason"
                  />
                </View>
              )}
              <View style={style.buttons}>
                {refundReason && refundState !== 'canceled' ? (
                  <EtherButton
                    text="Exit"
                    textColor="blurple"
                    style={[style.cancelButton, {marginRight: 0}]}
                    onPress={onPressClose}
                  />
                ) : (
                  <>
                    <EtherButton
                      text="Cancel"
                      textColor="blurple"
                      style={style.cancelButton}
                      onPress={onPressClose}
                    />
                    <EtherButton
                      disabled={!selectedReason}
                      status={status}
                      text="Refund"
                      textColor="light"
                      onPress={handleRefund}
                    />
                  </>
                )}
              </View>
            </>
          )}
        </View>
      </Pressable>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttons: {
    flexDirection: 'row',
  },
  cancelButton: {
    borderColor: theme.FIRST,
    backgroundColor: theme.LIGHT,
    marginRight: 15,
    width: 80,
  },
  container: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    margin: 'auto',
    borderRadius: 5,
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 300,
    width: 400,
    padding: 20,
    cursor: 'default',
    mobile: {
      width: 300,
    },
  },
  disclaimerText: {
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginTop: 5,
    textAlign: 'center',
  },
  dropdown: {
    zIndex: 9999,
    minWidth: 320,
    mobile: {
      minWidth: 250,
    },
  },
  dropdownText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    paddingVertical: 10,
    color: theme.DARK,
  },
  fraudText: {
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.RED,
    marginTop: -10,
    marginBottom: 5,
  },
  modal: {
    zIndex: 9999,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modeHeader: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 20,
  },
  modeSubText: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginBottom: 20,
  },
  modeTextContainer: {
    alignSelf: 'center',
    height: 'auto',
  },
  outerArea: {
    backgroundColor: theme.BLACKGROUND,
    flex: 1,
  },
  refundReason: {
    height: 86,
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  refundText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
  },
  renameContainer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    margin: 'auto',
    borderRadius: 5,
    height: 250,
    width: 460,
    padding: 20,
    cursor: 'default',
  },
  statusText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginTop: -20,
    textAlign: 'center',
  },
  title: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
});
