import {faTrash} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {View, FlatList, Text, TouchableOpacity} from 'react-native';

import FaIcon from './FaIcon';
import {useCart} from '../context/CartContext';
import {useTheme} from '../context/ThemeContext';
import {centsToDollars, ellipsify} from '../utils/common/funcs';

export default function Payment() {
  const {style} = useTheme(getThemedStyles);
  const {cart, removeItem, order} = useCart();
  const onSale = order?.price?.discountedSubtotal < order?.price?.subtotal;

  return (
    <View style={style.main}>
      <View style={style.header}>
        <Text style={style.headerText}> Item</Text>
        <Text style={style.headerText}>Price</Text>
      </View>
      <FlatList
        data={cart}
        style={{bottom: 2}}
        renderItem={({item}) => (
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity
              style={style.remove}
              onPress={() => removeItem(item)}
            >
              <FaIcon icon={faTrash} size={20} style={style.removeButton} />
            </TouchableOpacity>
            <View style={style.row}>
              <Text style={style.packText}>{ellipsify(item.name, 20)}</Text>
              <Text style={style.priceText}>{centsToDollars(item.price)}</Text>
            </View>
          </View>
        )}
        numColumns={1}
        keyExtractor={(item) => item.name}
      />
      <View style={style.subTotal}>
        {onSale ? (
          <Text style={style.subTotalText}>
            Subtotal:{' '}
            <span style={{marginLeft: 20, textDecoration: 'line-through'}}>
              {centsToDollars(order?.price?.subtotal)}
            </span>
          </Text>
        ) : null}
        <Text style={style.subTotalText}>
          {onSale ? 'Sale price:' : 'Subtotal:'}
          <span
            style={onSale ? {color: 'red', marginLeft: 20} : {marginLeft: 20}}
          >
            {centsToDollars(order?.price?.discountedSubtotal) || 0}
          </span>
        </Text>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 40,
    paddingHorizontal: 10,
  },
  headerText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.FIRST,
    marginLeft: 10,
  },
  main: {
    flex: 1,
    justifyContent: 'center',
    padding: 5,
    paddingHorizontal: 15,
  },
  packText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    paddingVertical: 20,
  },
  priceText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Regular',
    paddingVertical: 20,
  },
  remove: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
  },
  removeButton: {
    color: 'gray',
  },
  rightPanel: {
    flexDirection: 'row',
  },
  row: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  subTotal: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    margin: 'auto',
  },
  subTotalText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.FIRST,
  },
});
