import {faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {useIsFocused} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

import EtherButton from './EtherButton';
import StorefrontCart from './StorefrontCart';
import {useCart} from '../context/CartContext';
import {useTheme} from '../context/ThemeContext';
import {HEADER_HEIGHT} from '../utils/common/constants';
import {centsToDollars} from '../utils/common/funcs';
import {useIsMobile} from '../utils/common/hooks';
import {getSafeEvents} from '../utils/common/stores';

export default function StorefrontTopBar({route, navigation}) {
  const {style, values} = useTheme(getThemedStyles);
  const [loaded, setLoaded] = useState(null);
  const [storeInfo, setStoreInfo] = useState(null);
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const focused = useIsFocused();
  const {order} = useCart();

  const {isMobile} = useIsMobile();

  // Sometimes react navigation's header doesn't get params
  const storeSlug = route?.params?.storeSlug;

  useEffect(() => {
    async function getData() {
      if (!storeSlug) return;
      setLoaded(false);
      try {
        const {store} = await getSafeEvents(storeSlug);
        setStoreInfo(store);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoaded(true);
      }
    }

    getData();
  }, [storeSlug]);

  const buttons = [
    {
      text: 'Home',
      icon: null,
      onPress: () => {
        navigation.navigate('Store', {
          storeSlug,
        });
      },
    },
    {
      text: centsToDollars(order?.price?.discountedSubtotal || 0),
      icon: faCartShopping,
      onPress: () => {
        setShowCart(!showCart);
      },
    },
  ];

  useEffect(() => {
    if (!focused) setShowCart(false);
  }, [focused]);

  if (!loaded) {
    return <View style={style.topBar} />;
  }
  return (
    <View style={{overflow: 'visible'}}>
      <View style={[{height: HEADER_HEIGHT}, style.topBar]}>
        {!isMobile ? (
          <View style={style.leftSide}>
            <EtherButton
              style={style.orgName}
              basic
              onPress={() =>
                navigation.navigate('Store', {
                  storeSlug,
                })
              }
            >
              {storeInfo.orgname}
            </EtherButton>
          </View>
        ) : null}
        <View
          style={[
            style.rightSide,
            isMobile ? {minWidth: '100%', justifyContent: 'center'} : null,
          ]}
        >
          {buttons.map((button, index) => (
            <React.Fragment key={index}>
              <EtherButton
                basic
                text={button.text}
                icon={button.icon}
                iconSize={24}
                iconColor={values.DARK}
                iconStyle={{marginRight: 15}}
                textStyle={style.text}
                testID={index === 1 ? 'cart-price' : undefined}
                style={
                  index === hoveredButtonIndex
                    ? style.hoveredButton
                    : style.button
                }
                onMouseOver={() => setHoveredButtonIndex(index)}
                onMouseLeave={() => setHoveredButtonIndex(null)}
                onPress={button.onPress}
              />
              {index < 2 && <View style={style.lineBreak} />}
            </React.Fragment>
          ))}
        </View>
      </View>
      <StorefrontCart
        open={showCart}
        onHide={() => setShowCart(!showCart)}
        headerHeight={HEADER_HEIGHT}
        route={route}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  button: {
    padding: 7,
    borderWidth: 3,
    borderColor: 'rgba(0, 0, 0, 0)',
  },
  hoveredButton: {
    padding: 7,
    borderWidth: 3,
    borderColor: theme.DARK,
  },
  icon: {
    width: 60,
    height: 60,
    justifyContent: 'center',
  },
  leftSide: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  lineBreak: {
    height: 25,
    borderWidth: 1,
    borderColor: theme.DARK,
    alignSelf: 'center',
    marginHorizontal: 15,
  },
  orgName: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginLeft: 20,
  },
  rightSide: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: fontSize.bigBody,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  topBar: {
    height: 65,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.BGFIRST,
    borderColor: theme.DARK,
    borderBottomWidth: 2,
    paddingHorizontal: 10,
    position: 'fixed',
    overflow: 'visible',
    mobile: {
      paddingHorizontal: null,
    },
  },
  userLogo: {
    width: 50,
    height: 50,
    backgroundColor: 'magenta',
  },
});
