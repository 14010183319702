import etherFetch from '../ether-fetch/etherFetch';

const BASE_ROUTE = '/auth';

/**
 * Updates a user in the database
 * @param {string} userId - ID of the user to update
 * @param {Object} userChanges - Changes to the user object
 * @returns {Object} - (Message & Updated user object) OR error message
 */
export function updateUser(userId, userChanges) {
  return etherFetch([BASE_ROUTE, 'update-user'].join('/'), {
    method: 'POST',
    body: {userId, userChanges},
  }).then((res) => res.json());
}

/**
 * Lists users in the database
 * @param {number} page - Page number
 * @param {number} limit - Number of users per page
 * @param {string} query - Query to search for
 * @returns {Object} - (List of users & total number of users) OR error message
 */
export function listUsers(page, limit, query) {
  return etherFetch([BASE_ROUTE, 'list-users'].join('/'), {
    method: 'POST',
    body: {page, limit, query},
  }).then((res) => res.json());
}
