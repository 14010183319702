import {
  faDownload,
  faExternalLinkAlt,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import {number, string, shape} from 'prop-types';
import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, Pressable} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import FaIcon from './../FaIcon';
import EtherButton from '../../components/EtherButton';
import {useTheme} from '../../context/ThemeContext';
import {
  centsToDollars,
  ellipsify,
  formatDate,
  formatTime,
  getItemsSummary,
} from '../../utils/common/funcs.js';
import {getOrderDetails} from '../../utils/common/orders';
import RefundModal from '../RefundModal.js';

OrderDesktop.proptypes = {
  itemData: shape({
    date: string.isRequired,
    name: string.isRequired,
    orderNumber: string.isRequired,
    total: number.isRequired,
  }),
};
export function OrderDesktop({orderData}) {
  const {style, values} = useTheme(getThemedStyles);
  const [mouseHover, setMouseHover] = useState(false);
  const [extendedData, setExtendedData] = useState({});
  const [packList, setPackList] = useState([]);
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [downloadHover, setDownloadHover] = useState(false);
  const [refundHover, setRefundHover] = useState(false);

  const textHighlight = [
    style.valueText,
    mouseHover ? style.textHighlighted : null,
  ];

  function getDetails() {
    setOpen(!open);
    if (Object.keys(extendedData).length !== 0) {
      return;
    }
    getOrderDetails(orderData.orderNumber)
      .then((data) => {
        setExtendedData(data);
        setPackList(data.hydratedOrder.items.map((item) => item.pack.name));
      })
      .then(() => setLoaded(true))
      .catch((err) => console.error(err));
  }

  return (
    <Pressable
      onPress={() => {
        getDetails();
      }}
    >
      {showModal ? (
        <RefundModal
          orderId={orderData.orderNumber}
          onPressClose={() => setShowModal(false)}
          onRefundComplete={() => {
            setShowModal(false);
            toast.success('Refund successful');
          }}
        />
      ) : null}
      <View
        onMouseOver={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        style={[style.order, mouseHover ? style.orderHighlighted : null]}
      >
        <View style={style.cellDate}>
          <Text style={[style.boldText, textHighlight]}>
            {formatDate(orderData.purchased)}
          </Text>
          <Text style={[style.boldText, textHighlight]}>
            {formatTime(orderData.purchased)}
          </Text>
        </View>
        <View style={{width: 220, alignItems: 'center'}}>
          <Text style={[style.boldText, textHighlight]}>
            {getItemsSummary(orderData.events)}
          </Text>
          <Text style={textHighlight}>
            {getItemsSummary(orderData.packNames)}
          </Text>
        </View>
        <View style={style.cellOrderID}>
          <Text style={textHighlight}>{orderData.orderNumber}</Text>
        </View>
        <View style={style.cellPrice}>
          <Text style={[style.boldText, textHighlight]}>
            {centsToDollars(orderData.total)}
          </Text>
        </View>
        <View style={style.cellStatus}>
          {orderData.zippedPack?.url ? (
            <EtherButton
              href={orderData.zippedPack.url}
              onMouseEnter={() => setDownloadHover(true)}
              onMouseLeave={() => setDownloadHover(false)}
              style={[
                style.download,
                {
                  borderColor: mouseHover ? values.BGFIRST : values.FIRST,
                  backgroundColor:
                    mouseHover && downloadHover ? values.BGFIRST : null,
                },
              ]}
            >
              <FaIcon
                icon={faDownload}
                color={
                  mouseHover && downloadHover
                    ? values.FIRST
                    : mouseHover
                    ? values.BGFIRST
                    : values.FIRST
                }
              />
            </EtherButton>
          ) : (
            <Text
              style={[
                style.downloadText,
                {color: mouseHover ? values.BGSECOND : values.FIRST},
              ]}
            >
              Preparing download
            </Text>
          )}
        </View>
        <View style={style.cellStatus}>
          <EtherButton
            onPress={() => setShowModal(true)}
            onMouseEnter={() => setRefundHover(true)}
            onMouseLeave={() => setRefundHover(false)}
            style={[
              style.download,
              {
                borderColor: mouseHover ? values.BGFIRST : values.FIRST,
                backgroundColor:
                  mouseHover && refundHover ? values.BGFIRST : null,
              },
            ]}
          >
            <FaIcon
              icon={faRotateLeft}
              color={
                mouseHover && refundHover
                  ? values.FIRST
                  : mouseHover
                  ? values.BGFIRST
                  : values.FIRST
              }
            />
          </EtherButton>
        </View>
      </View>
      <div className={open ? 'open' : 'close'} style={style.extendedContainer}>
        {loaded ? (
          <View style={style.drawer}>
            <View style={style.cellDate}>
              <Text style={style.drawerBoldText}>Cart Creation Date</Text>
              <Text style={style.valueText}>{formatDate(orderData.date)}</Text>
              <Text style={style.valueText}>{formatTime(orderData.date)}</Text>
            </View>
            <View
              style={{
                width: 220,
                alignItems: 'center',
              }}
            >
              <Text style={style.drawerBoldText}>Packs</Text>
              {packList.map((name, index) => (
                <Text key={index}>{ellipsify(name, 28)}</Text>
              ))}
            </View>
            <View
              style={{
                width: 220,
                alignItems: 'center',
              }}
            >
              <Text style={style.drawerBoldText}>Events</Text>
              {orderData.events.map((name, index) => (
                <Text key={index}>{ellipsify(name, 28)}</Text>
              ))}
            </View>
            <View style={style.cellOrderID}>
              <Text style={style.drawerBoldText}>Customer</Text>
              <Text style={style.valueText}>
                {ellipsify(
                  extendedData.payment_intent.charges.data[0].billing_details
                    .name,
                  28,
                )}
              </Text>
              <Text style={style.valueText}>
                {
                  extendedData.payment_intent.charges.data[0].billing_details
                    .email
                }
              </Text>
              <Text style={style.valueText}>
                {
                  extendedData.payment_intent.charges.data[0].billing_details
                    .phone
                }
              </Text>
            </View>
            <View style={style.cellPrice}>
              <Text style={style.drawerBoldText}>Seller</Text>
              <Text style={style.valueText}>
                {ellipsify(extendedData.hydratedOrder.username, 14)}
              </Text>
            </View>
            <View style={style.cellReceipt}>
              <Text style={style.drawerBoldText}>Receipt</Text>
              <Pressable
                onPress={() => {
                  window.open(
                    `${extendedData.payment_intent.charges.data[0].receipt_url}`,
                  );
                }}
                hitSlop={20}
              >
                <FaIcon
                  size={18}
                  style={style.receipt}
                  icon={faExternalLinkAlt}
                />
              </Pressable>
            </View>
          </View>
        ) : (
          <ActivityIndicator style={{padding: 40}} />
        )}
      </div>
    </Pressable>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  boldText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
  },
  cellDate: {
    width: 170,
  },
  cellOrderID: {
    width: 170,
  },
  cellPrice: {
    borderColor: theme.FIRST,
    alignItems: 'center',
    width: 40,
  },
  cellReceipt: {
    alignItems: 'center',
    width: 58,
  },

  cellSelect: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 35,
  },
  cellStatus: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 70,
  },
  download: {
    borderRadius: 50,
    borderWidth: 2,
    padding: 3,
    height: 30,
    backgroundColor: null,
  },
  downloadText: {
    fontSize: fontSize.tiny,
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'NotoSans_Regular',
  },
  drawer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 8,
    paddingLeft: 8,
  },
  drawerBoldText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    marginBottom: 5,
  },
  emptyDrawer: {
    padding: 40,
    justifyContent: 'space-between',
  },
  extendedContainer: {
    backgroundColor: theme.BGSECOND,
  },
  order: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 8,
  },
  orderHighlighted: {
    backgroundColor: theme.SECOND,
  },
  receipt: {
    marginTop: 5,
  },
  stripeLink: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.tiny,
    marginTop: 2,
    color: theme.BGSECOND,
  },
  textHighlighted: {
    color: theme.LIGHT,
  },
  valueText: {
    alignSelf: 'center',
    fontSize: fontSize.legal,
  },
});
