import Cookie from 'js-cookie';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import toast from 'react-hot-toast';

import {
  addItem as apiAddItem,
  removeItem as apiRemoveItem,
  getOrder,
} from '../utils/common/orders';

const CartContext = React.createContext();

export function CartProvider({children}) {
  const [cart, setCart] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [cartUrl, setCartUrl] = useState(null);
  const [order, setOrder] = useState(null);

  const updateOrderId = useCallback((newId) => {
    setCartUrl(`http://${window.location.host}/checkout?cart=${newId}`);
    Cookie.set('orderId', newId, {path: '/'});
    setOrderId(newId);
  }, []);

  /**
   *
   * @param pack - pack to add to cart
   * @param {'kiosk'|'storefront'|'cart link'} checkoutMethod
   * @returns {Promise<void>}
   */
  async function addItem(pack, checkoutMethod) {
    const noItems = cart.length === 0;
    const alreadyAdded = cart.some(({packId}) => packId === pack.packId);
    const unique = noItems || !alreadyAdded;

    if (!unique) {
      toast.error('Item already in cart');
      return;
    }

    const orderIdCookie = Cookie.get('orderId');
    const updatedOrder = await apiAddItem(
      pack.packId,
      orderIdCookie || undefined,
      checkoutMethod,
    );
    if (!orderIdCookie || orderIdCookie !== updatedOrder._id) {
      updateOrderId(updatedOrder._id);
    }

    const {pack: updatedPack, price} = updatedOrder.items.find(
      (item) => item.pack._id === pack.packId,
    );

    const cartItem = {
      packId: updatedPack._id,
      assets: updatedPack.assets,
      name: updatedPack.name,
      price,
      event: updatedOrder.eventName,
    };
    setCart([...cart, cartItem]);
    setOrder(updatedOrder);
    toast.success(`${cartItem.name} added to your cart!`);
  }

  /**
   * Remove pack with the provided name from the cart
   *
   * @param {Object} pack - Pack to be removed
   */
  async function removeItem(pack) {
    try {
      const updatedOrder = await apiRemoveItem(pack.packId, orderId);

      const cartItems = updatedOrder.items.map((item) => ({
        packId: item.pack._id,
        assets: item.pack.assets,
        name: item.pack.name,
        price: item.price,
        event: updatedOrder.eventName,
      }));

      setCart(cartItems);
      setOrder(updatedOrder);
      // TODO: Add "Undo"
      toast.success(`${pack.name} was remove from your cart`);
    } catch (error) {
      console.error('Failed to remove item:', error.message);
      toast.error(`Failed to remove item: ${pack.name}`);
    }
  }

  /**
   * Clear cart and show a message
   *
   * @param {string} message - Message to display when cart is cleared
   */
  function clearCart(message = 'Cart has been cleared') {
    setCart([]);
    Cookie.remove('orderId');
    setOrderId(null);
    setOrder(null);
    if (message) toast.success(message);
  }

  function setItems(value) {
    setCart(value);
  }

  function changeCartUrl(url) {
    setCartUrl(url);
  }

  // Try to fetch existing cart from cookies, except on /download
  useEffect(() => {
    if (window.location.search.includes('/download')) {
      return;
    }

    const orderParam = new URLSearchParams(window.location.search).get('cart');
    const orderCookie = Cookie.get('orderId');
    const storedId = orderParam || orderCookie;
    if (storedId && storedId !== 'null') {
      getOrder(storedId).then((fetchedOrder) => {
        if (fetchedOrder) {
          const cartItems = fetchedOrder.items.map((item) => ({
            packId: item.pack._id,
            assets: item.pack.assets,
            name: item.pack.name,
            price: item.price,
            event: 'this is an event name',
          }));
          updateOrderId(fetchedOrder._id);
          setCart(cartItems);
          setOrder(fetchedOrder);
        } else {
          toast.error('Failed to load cart. Please try again later.');
        }
      });
    }
  }, [updateOrderId]);

  return (
    <CartContext.Provider
      value={{
        cart,
        addItem,
        removeItem,
        setItems,
        clearCart,
        orderId,
        updateOrderId,
        cartUrl,
        changeCartUrl,
        order,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

function useCart() {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }

  return context;
}

export {useCart};
export default CartContext;
