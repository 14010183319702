import React, {useEffect, useCallback, useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, ScrollView, TextInput} from 'react-native';

import EtherButton from './EtherButton';
import EtherInput from './EtherInput';
import {useTheme} from '../context/ThemeContext';
import {conditionalS} from '../utils/common/funcs';
import {updateUser, listUsers} from '../utils/common/users';

/**
 * Cell component for displaying a table cell
 * @param {string} label - Label of the cell
 * @param {string} value - Value of the cell
 * @param {boolean} [editable] - Whether the cell is editable
 * @param {function} onSave - Callback to save the cell value
 * @param {boolean} isEdited - Whether the cell is edited
 * @returns {JSX.Element} - Cell component
 */
function Cell({label, value, editable = false, onSave = () => {}, isEdited}) {
  const {style} = useTheme(getThemedStyles);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  function onClick() {
    if (editable) {
      setIsEditing(true);
    }
  }

  function onBlur() {
    setIsEditing(false);
    onSave(editValue);
  }

  function onChange(text) {
    setEditValue(text);
    onSave(text);
  }

  return (
    <>
      <Text style={style.tableHeader}>{label}</Text>
      {isEditing ? (
        <TextInput
          value={editValue}
          onChangeText={onChange}
          onBlur={onBlur}
          autoFocus
          style={[style.tableCell, isEdited && style.editedCell, {padding: 0}]}
        />
      ) : (
        <Text
          style={[
            style.tableCell,
            isEdited && style.editedCell,
            {
              fontFamily: editable ? 'NotoSans_Regular' : 'NotoSans_Bold',
              fontSize: editable ? 10 : 12,
            },
          ]}
          onPress={onClick}
        >
          {editValue}
        </Text>
      )}
    </>
  );
}

/**
 * Banner component - warns user of unsaved changes
 * @param {number} count - Number of changes
 * @param {function} onCancel - Callback to cancel changes
 * @param {function} onSave - Callback to save changes
 * @returns {JSX.Element} - Banner component
 */
function ChangesBanner({count, onCancel, onSave}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.changesBanner}>
      <Text style={style.changesPendingText}>
        WARNING: You have {count} unsaved change{conditionalS(count > 1)}
      </Text>
      <View style={{flexDirection: 'row', gap: 8}}>
        <EtherButton
          style={style.cancelButton}
          text="Cancel"
          textColor="dark"
          onPress={onCancel}
        />
        <EtherButton
          style={{height: 24, width: 105}}
          text="Update"
          onPress={onSave}
        />
      </View>
    </View>
  );
}

/**
 * Page controls component - allows user to navigate through pages
 * @param {function} onPrevious - Callback to navigate to previous page
 * @param {function} onNext - Callback to navigate to next page
 * @param {boolean} disabledPrevious - Whether the previous button is disabled
 * @param {boolean} disabledNext - Whether the next button is disabled
 * @returns {JSX.Element} - Page controls component
 */
function PageControls({onPrevious, onNext, disabledPrevious, disabledNext}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <View style={style.pageControls}>
      <EtherButton
        text="<"
        onPress={onPrevious}
        disabled={disabledPrevious}
        style={{
          borderRightWidth: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />
      <EtherButton
        text=">"
        onPress={onNext}
        disabled={disabledNext}
        style={{
          borderLeftWidth: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />
    </View>
  );
}

/**
 * Search bar component
 * @param {function} onSearch - Callback to search
 * @returns {JSX.Element} - Search bar component
 */
function SearchBar({onSearch}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <EtherInput
      placeholder="Search"
      onChangeText={onSearch}
      style={style.searchBar}
    />
  );
}

/**
 * User table row component
 * @param {Object} user - User object
 * @param {Object} pendingChanges - Pending changes object
 * @param {function} onSaveCell - Callback to save changes
 * @param {function} onSubmitChanges - Callback to save changes
 * @param {function} onCancelChanges - Callback to cancel changes
 * @returns {JSX.Element} - User table row component
 */
function UserTableRow({
  user,
  pendingChanges,
  onSaveCell,
  onSubmitChanges,
  onCancelChanges,
}) {
  const {style} = useTheme(getThemedStyles);
  const name = `${user.identification.fname || ''} ${
    user.identification.lname || ''
  }`;

  const isEdited = (field) => pendingChanges?.[field] !== undefined;

  return (
    <View style={style.tableRowContainer}>
      <View style={style.tableRow}>
        <View style={style.tableStack}>
          <Cell label="Name" value={name} />
          <Cell label="Username" value={user.username} />
        </View>
        <View style={style.tableStack}>
          <Cell label="Phone" value={user.identification.phone} />
          <Cell label="Organization" value={user.identification.orgname} />
        </View>
        <View style={style.tableStack}>
          <Cell
            label="Email"
            value={pendingChanges?.email || user.identification.email}
            editable
            isEdited={isEdited('email')}
            onSave={(value) => onSaveCell(user.userId, 'email', value)}
          />
          <Cell
            label="Rev Share"
            value={pendingChanges?.revShare || user.revShare}
            editable
            isEdited={isEdited('revShare')}
            onSave={(value) => onSaveCell(user.userId, 'revShare', value)}
          />
        </View>
        <View style={style.tableStack}>
          <Cell
            label="Storage Limit"
            value={pendingChanges?.bytesLimit || user.storage.bytesLimit}
            editable
            isEdited={isEdited('bytesLimit')}
            onSave={(value) =>
              onSaveCell(user.userId, 'bytesLimit', Number(value))
            }
          />
          <Cell
            label="Kiosk Count"
            value={pendingChanges?.kioskCount || user.kioskCount}
            editable
            isEdited={isEdited('kioskCount')}
            onSave={(value) => onSaveCell(user.userId, 'kioskCount', value)}
          />
        </View>
      </View>

      {pendingChanges && Object.keys(pendingChanges).length > 0 ? (
        <ChangesBanner
          count={Object.keys(pendingChanges).length}
          onSave={() => onSubmitChanges(user.userId)}
          onCancel={() => onCancelChanges(user.userId)}
        />
      ) : null}
    </View>
  );
}

/**
 * User viewer component
 * @returns {JSX.Element} - User viewer component
 */
export default function UserViewer() {
  const {style} = useTheme(getThemedStyles);
  const [users, setUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [usersPage, setUsersPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [pendingChanges, setPendingChanges] = useState({});
  const limit = 10;

  useEffect(() => {
    fetchUsers({page: usersPage, query: searchQuery});
  }, [usersPage, searchQuery, fetchUsers]);

  const fetchUsers = useCallback(async ({page, query}) => {
    try {
      const data = await listUsers(page, limit, query);
      setUsers(data.users);
      setOriginalUsers(data.users);
      setTotalUsers(data.totalUsers);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  }, []);

  function handleCellSave(userId, field, newValue) {
    const originalUser = originalUsers.find((u) => u.userId === userId);
    const originalValue = originalUser?.[field] || 'N/A';

    setPendingChanges((prev) => {
      const isSameAsOriginal = newValue === originalValue.toString();
      const userChanges = prev[userId] || {};

      if (isSameAsOriginal) {
        delete userChanges[field];
      } else {
        userChanges[field] = newValue;
      }

      if (Object.keys(userChanges).length === 0) {
        return prev || {};
      }

      return {...prev, [userId]: userChanges};
    });
  }

  async function handleSaveChanges(userId) {
    const userChanges = pendingChanges[userId];
    if (!userChanges) return;

    try {
      const data = await updateUser(userId, userChanges);
      toast.success('User updated successfully');
      fetchUsers({page: usersPage, query: searchQuery});
      setPendingChanges((prev) => {
        const newChanges = {...prev};
        delete newChanges[userId];
        return newChanges;
      });
      return data;
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  }

  async function handleCancelChanges(userId) {
    setPendingChanges((prev) => {
      const newChanges = {...prev};
      delete newChanges[userId];
      return newChanges;
    });
  }

  return (
    <View style={style.table}>
      <View style={style.tableControls}>
        <PageControls
          onPrevious={() => setUsersPage((p) => p - 1)}
          onNext={() => setUsersPage((p) => p + 1)}
          disabledPrevious={usersPage === 0}
          disabledNext={(usersPage + 1) * limit >= totalUsers}
        />
        <SearchBar onSearch={setSearchQuery} />
      </View>

      <ScrollView horizontal>
        <View style={style.table}>
          {users.map((user) => (
            <UserTableRow
              key={user.userId}
              user={user}
              pendingChanges={pendingChanges[user.userId]}
              onSaveCell={handleCellSave}
              onSubmitChanges={handleSaveChanges}
              onCancelChanges={handleCancelChanges}
            />
          ))}
        </View>
      </ScrollView>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  cancelButton: {
    height: 24,
    backgroundColor: theme.BGFIRST,
  },
  changesBanner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 4,
    backgroundColor: theme.RED,
    color: theme.LIGHT,
    borderWidth: 3,
    borderTopWidth: 0,
    borderColor: theme.DARK,
    marginTop: -10,
    marginBottom: 10,
  },
  changesPendingText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 10,
    letterSpacing: 1,
    color: theme.LIGHT,
  },
  editedCell: {
    backgroundColor: '#FFB7B7',
  },
  pageControls: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBar: {
    width: '80%',
  },
  table: {
    marginTop: 20,
  },
  tableCell: {
    width: 115,
    height: 44,
    fontFamily: 'NotoSans_Regular',
    fontSize: 10,
    color: theme.DARK,
    borderWidth: 2,
    borderColor: theme.DARK,
    textAlign: 'center',
    alignContent: 'center',
  },
  tableControls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  tableHeader: {
    width: 115,
    height: 22,
    fontFamily: 'NotoSans_Bold',
    fontSize: 14,
    color: theme.DARK,
    backgroundColor: theme.BGFIRST,
    borderWidth: 2,
    borderColor: theme.DARK,
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 10,
    borderWidth: 2,
    borderColor: theme.DARK,
  },
  tableStack: {
    flexDirection: 'column',
    maxHeight: 120,
  },
});
