import React from 'react';
import {View, Text} from 'react-native';
import Select from 'react-select';

import {useTheme} from '../../context/ThemeContext';
import {ellipsify} from '../../utils/common/funcs';

export function FilterMenu({orders, filters, setFilter}) {
  const {style, values} = useTheme(getThemedStyles);
  const eventsArray = orders.flatMap((order) => order.events);
  const uniqueEvent = [...new Set(eventsArray)];
  const options = uniqueEvent.map((o) => ({
    value: o,
    label: ellipsify(o, 28),
  }));

  const customStyle = {
    option: (provided, state) => {
      return {
        ...provided,
        fontFamily: 'NotoSans_Bold',
        fontSize: 12,
        color: state.isSelected
          ? values.LIGHT
          : state.isFocused
          ? values.LIGHT
          : values.DARK,
        backgroundColor: state.isSelected
          ? values.FIRST
          : state.isFocused
          ? values.SECOND
          : 'white',
        padding: 10,
        ':active': {
          color: 'white',
          backgroundColor:
            !state.isDisabled &&
            (state.isSelected ? values.SECOND : values.FIRST),
        },
      };
    },
    control: (provided) => {
      return {
        ...provided,
        maxWidth: 820,
        minWidth: 200,
        fontFamily: 'NotoSans_Bold',
        fontSize: 14,
        borderColor: values.FIRST,
        borderWidth: 2,
        boxShadow: 'none',
        ':hover': {
          borderColor: values.FIRST,
        },
      };
    },
  };

  return (
    <View style={style.filterContainer}>
      <Text style={style.filterText}>Filter by event</Text>
      <Select
        isMulti
        options={options}
        onChange={setFilter}
        value={filters}
        styles={customStyle}
        noOptionsMessage={() => <Text>No options</Text>}
      />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  filterContainer: {
    maxWidth: 950,
    height: 20,
    alignSelf: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 20,
    zIndex: 10,
  },
  filterText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 14,
    marginRight: 40,
  },
});
