import {
  faFile,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faCaretRight,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import {oneOfType, object, number, string, array} from 'prop-types';
import {Resizable} from 're-resizable';
import React, {useState} from 'react';
import {View, Text, FlatList, ActivityIndicator} from 'react-native';

import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import ProgressBar from '../components/ProgressBar.web';
import {useTheme} from '../context/ThemeContext';
import {useAssets} from '../context/assets-context/AssetsContext';
import {ellipsify, conditionalS} from '../utils/common/funcs';
import {useWindowDimensions} from '../utils/common/hooks';

PackListItem.propTypes = {
  packName: string,
  content: array,
};
function PackListItem({packName, content}) {
  const [collapsed, setCollapsed] = useState(true);
  const {style, values} = useTheme(getThemedStyles);

  const inProgress = content.some((item) => item[1].status === 'pending');

  return (
    <>
      <EtherButton
        basic
        style={style.listItem}
        onPress={() => setCollapsed(!collapsed)}
      >
        <View style={style.packListItemLeft}>
          {collapsed ? (
            <FaIcon
              icon={faCaretRight}
              color={values.DARK}
              size={21}
              style={style.caretIcon}
            />
          ) : (
            <FaIcon
              icon={faCaretDown}
              color={values.DARK}
              size={21}
              style={style.caretIcon}
            />
          )}
          <Text style={style.listText}>{ellipsify(packName, 20)}</Text>
        </View>
        {inProgress ? (
          <ActivityIndicator
            style={style.packActive}
            size="small"
            color={values.FIRST}
          />
        ) : null}
      </EtherButton>
      {!collapsed ? (
        <FlatList
          data={content}
          keyExtractor={(item) => item[0]}
          renderItem={({item}) => (
            <AssetListItem
              name={item[1].name}
              progress={item[1].percentage}
              result={item[1].status}
            />
          )}
        />
      ) : null}
    </>
  );
}

AssetListItem.propTypes = {
  name: string,
  progress: number,
  result: string,
};
function AssetListItem({name, progress, result}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <View style={style.listItem}>
      <Text style={style.listText}>{ellipsify(name, 20)}</Text>
      <>
        {progress < 100 ? (
          <ProgressBar
            progress={progress}
            color={values.SECOND}
            height={10}
            width={100}
          />
        ) : null}

        {result === 'pending' ? (
          <ActivityIndicator size="small" color={values.FIRST} />
        ) : result === 'fulfilled' ? (
          <FaIcon icon={faCheckCircle} color={values.SECOND} size={27} />
        ) : (
          <FaIcon icon={faTimesCircle} color={values.RED} size={27} />
        )}
      </>
    </View>
  );
}

UploadInfoMenu.propTypes = {
  style: oneOfType([object, number]),
  uploadInfo: object,
};
export default function UploadInfoMenu({
  style: overrides,
  uploadInfo,
  inProgress = false,
}) {
  const [minimized, setMinimized] = useState(false);
  const minHeight = 300;
  const {height: viewportHeight} = useWindowDimensions();
  const maxHeight = viewportHeight * 0.7;
  const [height, setHeight] = useState(minHeight);
  const midpoint = (maxHeight + minHeight) / 2;
  const defaultWidth = 300;
  const [width, setWidth] = useState(defaultWidth);
  const {style, values} = useTheme(getThemedStyles);
  const {pack} = useAssets();

  const uploadInfoArr = Object.entries(uploadInfo.current);

  const parsedPacks = [];

  uploadInfoArr.forEach((file) => {
    const pathArr = file[1].path.split('/');
    const packName = pathArr[pathArr.length - 2];
    if (
      !parsedPacks.some((item) => {
        return item.packName === packName;
      })
    ) {
      parsedPacks.push({
        packName,
        content: [],
      });
    }
    parsedPacks.forEach((item) => {
      if (item.packName === packName) {
        item.content.push(file);
      }
    });
  });

  let successfulUploads = 0;
  if (inProgress === false) {
    uploadInfoArr.forEach((item) => {
      if (item[1].status === 'fulfilled') {
        successfulUploads = ++successfulUploads;
      }
    });
  }

  function toggleHeight(event, direction, refToElement, delta) {
    if (delta) {
      setHeight(height + delta.height);
      setWidth(width + delta.width);
      return;
    }
    if (height <= midpoint) {
      setHeight(maxHeight);
    } else {
      setHeight(minHeight);
    }
  }

  if (minimized) {
    return (
      <EtherButton
        style={[style.mainViewMinimized, overrides]}
        onPress={() => setMinimized(false)}
      >
        <FaIcon icon={faFile} color={values.SECOND} size={25} />
      </EtherButton>
    );
  }

  return (
    <View style={style.mainViewExpanded}>
      <View style={style.header}>
        {inProgress ? (
          <Text style={style.headerText}>
            Uploading {uploadInfoArr.length} item
            {conditionalS(uploadInfoArr.length)}...
          </Text>
        ) : (
          <Text style={style.headerText}>
            Upload Complete. {successfulUploads} item
            {conditionalS(successfulUploads)} uploaded.
          </Text>
        )}
        <View style={style.controls}>
          <EtherButton basic onPress={toggleHeight}>
            <FaIcon
              icon={height <= midpoint ? faCaretUp : faCaretDown}
              color={values.LIGHT}
              size={22}
              style={style.closeIcon}
            />
          </EtherButton>
          <EtherButton basic onPress={() => setMinimized(true)}>
            <FaIcon
              icon={faTimes}
              color={values.LIGHT}
              size={22}
              style={style.closeIcon}
            />
          </EtherButton>
        </View>
      </View>
      <Resizable
        style={style.resizable}
        size={{
          width,
          height,
        }}
        onResizeStop={(event, direction, refToElement, delta) =>
          toggleHeight(event, direction, refToElement, delta)
        }
        maxHeight={maxHeight}
        maxWidth="600"
        minHeight={minHeight}
        minWidth={defaultWidth}
        enable={{
          top: true,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: true,
        }}
      >
        {uploadInfoArr.length > 0 ? (
          <FlatList
            data={parsedPacks}
            keyExtractor={(item) => item.packName}
            renderItem={({item}) => (
              <PackListItem
                packName={item.packName || pack}
                content={item.content}
              />
            )}
          />
        ) : null}
      </Resizable>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  controls: {
    flexDirection: 'row',
  },
  header: {
    overflow: 'hidden',
    width: '100%',
    height: 30,
    backgroundColor: theme.SECOND,
    borderBottomWidth: 2,
    borderBottomColor: theme.FIRST,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 5,
  },
  headerText: {
    alignSelf: 'center',
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  listItem: {
    flexDirection: 'row',
    height: 45,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.FIRST,
    backgroundColor: theme.BGSECOND,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  listText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
  },
  mainViewExpanded: {
    overflow: 'hidden',
    backgroundColor: theme.BGSECOND,
    borderStyle: 'solid',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: theme.FIRST,
  },
  mainViewMinimized: {
    marginLeft: 10,
    cursor: 'pointer',
    backgroundColor: theme.BGSECOND,
    borderColor: theme.FIRST,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: '50%',
    width: 45,
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
  },
  packListItemLeft: {
    flexDirection: 'row',
  },
  resizable: {
    overflow: 'hidden visible',
    backgroundColor: theme.BGSECOND,
  },
});
