import {func, string} from 'prop-types';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, ActivityIndicator} from 'react-native';

import Toggleable from './Toggleable';
import EtherButton from '../../components/EtherButton';
import EtherCopyButton from '../../components/EtherCopyButton';
import EtherInput from '../../components/EtherInput';
import UserTable from '../../components/UserViewer';
import {useAdminData} from '../../context/AdminContext';
import {useTheme} from '../../context/ThemeContext';
import {createSignUpCode} from '../../utils/common/signUpCodes';

const HeaderMessages = [
  'Admins only',
  'Type the magic word: sudo rm -rf ',
  '0xDEADBEEF',
  'Password, please!',
  'Warning: Trespassers will be [redacted]',
  'Admin zone ahead',
  '↑ ↑ ↓ ↓ ← → ← → B A',
  'while(!asleep) sheep++',
  'hunter2',
  'Also try Minecraft!',
];

const randomIndex = Math.floor(Math.random() * HeaderMessages.length);
const randomMessage = HeaderMessages[randomIndex];

function RandomHeaderText() {
  const {style} = useTheme(getThemedStyles);

  return <Text style={style.mainHeader}>{randomMessage}</Text>;
}

Divider.propTypes = {
  header: string.isRequired,
  description: string.isRequired,
};

function Divider({header, description}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <View style={{alignItems: 'center', marginVertical: 20}}>
      <Text style={style.dividerText}>{header}</Text>
      <Text style={[style.text, {textAlign: 'center'}]}>{description}</Text>
      <View style={style.dividerLine} />
    </View>
  );
}

InputField.propTypes = {
  text: string.isRequired,
  description: string.isRequired,
  message: string,
  onChangeMessage: func,
};

function InputField({text, testID, description, message, onChangeMessage}) {
  const {style} = useTheme(getThemedStyles);
  return (
    <>
      <View style={style.inputField}>
        <Text style={[style.header, {marginBottom: 10}]}>{text}</Text>
        <Text style={[style.text, {marginBottom: 20}]}>{description}</Text>
        <EtherInput
          value={message}
          testID={testID}
          inputStyle={style.inputMessage}
          multiline
          numberOfLines={10}
          onChangeText={onChangeMessage}
        />
      </View>
    </>
  );
}

export default function AdminPanel() {
  const {style} = useTheme(getThemedStyles);
  const {adminData, save, loaded} = useAdminData();
  const [signUpCode, setSignUpCode] = useState('');

  const [localAdminData, setLocalAdminData] = useState(null);
  useEffect(() => {
    if (!localAdminData && loaded) {
      setLocalAdminData(adminData);
    }
  }, [adminData, loaded, localAdminData]);

  function handleSave() {
    save(localAdminData).then((res) =>
      toast.success(res.msg || 'Changes Saved.'),
    );
  }

  function update(key, val) {
    setLocalAdminData({
      ...localAdminData,
      [key]: val,
    });
  }

  async function getSignUpCode() {
    try {
      const code = await createSignUpCode();
      setSignUpCode(code);
    } catch (error) {
      console.error('Failed to create sign-up code:', error);
    }
  }

  return (
    <View style={style.main}>
      <RandomHeaderText />
      <View style={style.body}>
        <EtherButton
          text="Save Changes"
          testID="button-save-changes"
          onPress={handleSave}
        />
        <Divider
          header="Site settings"
          description="Configurations for the EtherMedia platform."
        />
        {loaded ? (
          <>
            <Toggleable
              text="Skip Stripe"
              description="Allow new users to skip the Stripe linking process."
              value={localAdminData?.skipStripe}
              onToggle={(val) => update('skipStripe', val)}
            />
            <Toggleable
              text="Generate Video Previews Locally"
              description="Uses FFmpeg to generate video previews. If disabled, Cloudinary will be used instead."
              value={localAdminData?.generateVideoPreviewsLocally}
              onToggle={(val) => update('generateVideoPreviewsLocally', val)}
            />
            <Toggleable
              text="Use GPU For Video Processing"
              description="If enabled, FFmpeg will use the GPU for video processing. If disabled it will use the CPU."
              value={localAdminData?.enableGpuEncoding}
              onToggle={(val) => update('enableGpuEncoding', val)}
            />
            <InputField
              text="Checkout Error Message:"
              testID="field-checkout-error-msg"
              description="The error message shown to the user if there is a problem at checkout."
              message={localAdminData?.checkoutErrorMessage}
              onChangeMessage={(val) => update('checkoutErrorMessage', val)}
            />
            <InputField
              text="Maximum Upload Queue Size"
              testID="field-max-upload-queue"
              description="The maximum number of files that can be queued for upload in the Asset Manager at once. Default is 100. Maximum is 1000."
              message={`${localAdminData?.maxUploadQueueSize}`}
              onChangeMessage={(val) => {
                const numVal = parseInt(val, 10);
                update('maxUploadQueueSize', numVal);
              }}
            />
            <InputField
              text="Unpurchased Order Lifetime"
              testID="field-unpurchased-order-lifetime"
              description="The number of days an unpurchased order (cart link) will remain valid before being deleted."
              message={`${localAdminData?.unpurchasedOrderLifetimeInDays}`}
              onChangeMessage={(val) =>
                update('unpurchasedOrderLifetimeInDays', val)
              }
            />
            <InputField
              text="Purchased Order Zip Lifetime"
              testID="field-purchased-zip-lifetime"
              description="The number of days after an order is purchased that the zipped assets will remain accessible before being deleted."
              message={`${localAdminData?.purchasedOrderZipLifetimeInDays}`}
              onChangeMessage={(val) =>
                update('purchasedOrderZipLifetimeInDays', val)
              }
            />
            <InputField
              text="Abandoned Cart Expiration Email Window"
              testID="field-cart-expiration-email-window"
              description="The number of days before an abandoned cart expires that we should send a reminder email."
              message={`${localAdminData?.cartExpirationEmailReminderWindowInDays}`}
              onChangeMessage={(val) =>
                update('cartExpirationEmailReminderWindowInDays', val)
              }
            />
            <InputField
              text="Zipped Assets Expiration Email Window"
              testID="field-zip-expiration-email-window"
              description="The number of days before a purchased order's zipped assets expire that we should send a reminder email."
              message={`${localAdminData?.zippedPackExpirationEmailReminderWindowInDays}`}
              onChangeMessage={(val) =>
                update('zippedPackExpirationEmailReminderWindowInDays', val)
              }
            />
            <Divider
              header="Access Codes"
              description="Codes for creating EtherMedia accounts, beta & beyond."
            />
            <EtherCopyButton
              buttonText="Get Access Code"
              copyText={signUpCode}
              onPress={getSignUpCode}
            />
            <Divider
              header="User Viewer"
              description="Compendium of clients and accounts connected to EtherMedia."
            />
            <UserTable />
          </>
        ) : (
          // Loading spinner
          <div
            style={{
              width: '100%',
              height: '200px',
              textAlign: 'center',
            }}
          >
            <Text style={{fontFamily: 'NotoSans_Regular'}}>
              Loading admin data...
            </Text>
            <ActivityIndicator size="large" style={{marginTop: 24}} />
          </div>
        )}
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  body: {
    width: 550,
    maxWidth: 550,
    padding: 40,
    margin: 40,
    borderRadius: 20,
    borderWidth: 4,
    borderColor: theme.FIRST,
    backgroundColor: theme.BGFIRST,
    mobile: {
      width: 340,
      padding: 20,
    },
  },
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
    borderColor: theme.DARK,
  },
  dividerLine: {
    marginTop: 20,
    width: '100%',
    height: 1,
    backgroundColor: theme.FIRST,
  },
  dividerText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 20,
    color: theme.DARK,
  },
  dropdown: {
    marginVertical: 20,
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 16,
    color: theme.DARK,
  },
  inputField: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  inputMessage: {
    paddingTop: 5,
    height: 36,
  },
  main: {
    padding: 40,
    alignItems: 'center',
  },
  mainHeader: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 32,
    color: theme.DARK,
  },
  text: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    color: theme.DARK,
  },
});
